:root {
  --marg_max: 60px;
  --marg_far: 30px;
  --marg_mid: 20px;
  --marg_min: 10px;

  --pad_max: 30px;
  --pad_mid: 20px;
  --pad_min: 10px;

  --blue: #3437ff;
  --dark_blue: #2b00a1;
  --dark_yellow: #f1a411;
  --darker_yellow: #c4840d;
  --green: #16a735;
  --dark_green: #0c6820;
  --red: #e9420f;
  --dark_red: #b6330b;
  --deep_red: #c41414;
  --grey: #aaaaaa;
  --dark_grey: #545454;
  --dark: #1c1c1c;
  --dark2: #2f2f2f;
  --pink: #ff258b;
  --dark_pink: #af185e;
  --sky_blue: #22a8c9;
  --dark_sky_blue: #0a6981;
  --bright_green: #48d932;
  --dark_green2: #279716;

  --border_rad_max: 30px;
  --border_rad_mid: 15px;
  --border_rad_min: 10px;

  --body-font: "Roboto", Arial, Helvetica Neue, Helvetica, sans-serif;
  /* --heading-font: "Roboto Slab", Cambria, "Hoefler Text", Utopia,
    "Liberation Serif", "Nimbus Roman No9 L Regular", Times, "Times New Roman",
    serif; */
}

/* Basic */
* {
  box-sizing: border-box;
}

body {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  user-select: none;
}

.selectable {
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
  user-select: text;
}

.line_break_flex {
  flex-basis: 100%;
  height: 0;
}

body {
  background: var(--dark);
  overflow-y: scroll;
  font-size: 17px;
  font-size: calc(17px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
  /* font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])); */
  font-family: var(--body-font);
  font-weight: 300;
  color: #fff;
}

#root {
  max-width: 800px;
  margin: 0 auto;
  padding: var(--pad_max) var(--pad_min);
}

/* Buttons */
.btn {
  padding: var(--pad_min);
  border: 0;
  border-radius: var(--border_rad_min);
  color: white;
  font-weight: 300;
  background-color: var(--blue);
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.5s;
  height: 44px;
}

.btn.deactivated {
  border-color: #6f6f6f;
  background-color: #474747;
  cursor: default;
}

.btn_round {
  border-radius: 100%;
  width: 44px;
  height: 44px;
}

.btn_red {
  background-color: var(--red);
}

.btn_yellow {
  background-color: var(--dark_yellow);
}

.btn_round {
  border-radius: 100%;
  width: 44px;
  height: 44px;
}

.btn i {
  margin-left: var(--marg_min);
}

@media (hover: hover) {
  /* If user is using desktop browser */
  .btn:active:not(.deactivated) {
    box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.473);
  }

  .btn:hover:not(.deactivated) {
    background-color: var(--dark_blue);
  }

  .btn_red:hover:not(.deactivated) {
    background-color: var(--dark_red);
  }

  .btn_yellow:hover:not(.deactivated) {
    background-color: var(--darker_yellow);
  }
}

/* Selector switch */
.selector_opt {
  border: 2px solid var(--blue);
  padding: var(--pad_min);
  border-radius: var(--border_rad_min);
  width: 95px;
  display: inline-block;
  text-align: center;
  background: transparent;
}

.selector_opt:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.selector_opt:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -2px; /* To avoid too thick right border between options */
}

.active_opt {
  background: var(--blue);
}

/* Toggle */
.toggle_box {
  display: flex;
  align-items: center;
}

.toggle_box:not(:first-child) {
  margin-top: var(--marg_mid);
}

.toggle_box:not(:last-child) {
  border-bottom: 1px solid var(--dark_grey);
  padding-bottom: var(--pad_mid);
}

.toggle_text {
  margin-left: var(--marg_mid);
}

.toggle_desc {
  display: block;
  color: var(--grey);
  font-size: var(--toggle_desc_size);
  margin-top: var(--marg_min);
  max-width: 400px;
}

.toggle {
  min-width: 60px;
  width: 60px;
  height: 30px;
  border-radius: 30px;
  position: relative;
  background: var(--dark_grey);
  cursor: pointer;
}

.toggle_knob {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  position: absolute;
  left: 0;
  transition: left 0.15s, background 0.15s;
}

.toggle_on {
  left: 30px;
  background: var(--green);
}

.toggle_off {
  left: 0;
  background: var(--red);
}

/* Pin */
.pin {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.pin i {
  margin: 0;
}

.pin_corner {
  position: absolute;
  right: -5px;
  top: -10px;
}

.pin.pin_active {
  background-color: var(--green);
}

@media (hover: hover) {
  .pin.pin_active:hover {
    background-color: var(--dark_green);
  }
}

/* Section */
.section {
  margin-bottom: var(--marg_max);
}

.section_title {
  font-size: 3rem;
  color: #fff;
  margin-bottom: var(--marg_mid);
  display: block;
  font-weight: 900;
}

/* Title */
.site_title {
  display: block;
  font-size: 5rem;
  font-weight: 900;
  line-height: 1;
}

.site_subtitle {
  line-height: 1;
  font-size: 1.8rem;
  display: block;
  margin-top: var(--marg_min);
}

/* Account */
#auth_form {
  border-radius: var(--border_rad_mid);
  padding: var(--pad_mid);

  width: 360px;

  color: #fff;
  background: var(--dark2);

  position: absolute;
  left: calc(50% - 180px);
  top: calc(50% - 50px);
}

#txt_input_pwd {
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: var(--marg_min);
  display: block;
}

#auth_form_input_line {
  display: flex;
}

#input_pwd {
  border-radius: var(--border_rad_min);
  border: none;
  outline: none;

  background: var(--dark2);
  color: white;
  box-shadow: inset 0px 0px 36px 1px black;

  padding: var(--pad_min);

  flex-grow: 2;

  -webkit-appearance: none;
}

#btn_auth {
  margin-left: var(--marg_min);
}

#btn_auth i {
  margin: 0;
}

#auth_error {
  display: block;
  margin-top: var(--marg_mid);
  color: var(--red);
  word-wrap: break-word;
  font-weight: 400;
}

#logout_box {
  float: right;
}

/* Navbar */
.navbar_box {
  z-index: 2;
  position: sticky;
  top: var(--marg_min);
}

.navbar,
.last_online_time,
.error_bar {
  box-shadow: 0 0 11px 3px var(--dark);
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  padding: 0 var(--pad_min);
  border-radius: var(--border_rad_min);
  justify-content: space-between;
  align-items: center;
  background: hsla(0, 0%, 18%, 0.97);
  height: 65px;
}

.last_online_time {
  border-radius: var(--border_rad_min);
  padding: var(--pad_min);
  float: right;
  background-color: var(--dark_grey);
  margin-top: var(--marg_min);
  font-size: 0.9rem;
}

/* Data boxes */
.category {
  margin-bottom: var(--marg_max);
}

.data_boxes_container {
  display: flex;
  flex-wrap: wrap;
}

.data_box {
  position: relative;
  background: var(--dark2);
  padding: var(--pad_mid);
  border-radius: var(--border_rad_mid);
  margin-right: var(--marg_mid);
  margin-bottom: var(--marg_mid);
  color: white;
}

.data_box_name {
  display: block;
  font-weight: 500;
  font-size: 0.9rem;
  color: #868686;
  margin-bottom: var(--marg_min);
}

.data_box_value {
  display: block;
  font-size: 2rem;
}

/* Data table */
.data_table {
  border-collapse: collapse;
  width: 100%;
  max-width: 600px;
}

@media only screen and (max-width: 426px) {
  /* Smartphones */
  .data_table {
    table-layout: fixed;
  }
}

.data_table th {
  font-weight: 900;
  text-align: left;
  padding: var(--pad_mid);
  background: #fff;
  color: black;
}

.data_table td {
  padding: var(--pad_mid);
}

.data_table tr:nth-child(even) td {
  background: var(--dark2);
}

.data_table th:first-of-type,
.data_table td:first-of-type {
  border-top-left-radius: var(--border_rad_min);
  border-bottom-left-radius: var(--border_rad_min);
}
.data_table th:last-of-type,
.data_table td:last-of-type {
  border-top-right-radius: var(--border_rad_min);
  border-bottom-right-radius: var(--border_rad_min);
}

.data_row_pin {
  text-align: right;
}

td.table_empty_notice {
  padding: var(--marg_far) 0;
  position: absolute;
  display: flex;
  align-items: center;
}

.table_empty_notice .pin {
  margin: 0 var(--marg_mid);
}

/* Error */
.error_view {
  z-index: 2;
  top: 75px;
  position: sticky;
  margin-bottom: var(--marg_max);
}
.error_bar {
  padding: var(--pad_min);
  border-radius: var(--border_rad_min);
  background: var(--deep_red);
  display: inline-flex;
  align-items: center;
  font-size: 1.5rem;
  flex-wrap: wrap;
  margin-top: var(--marg_min);
  margin-right: var(--marg_min);
}

.error_category {
  display: inline-block;
  font-weight: 500;
  margin-left: var(--marg_min);
}

.error_description {
  margin-left: var(--marg_min);
}

/* Data state indicator */
.loader_animation {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  width: 80px;
  height: 80px;
}

.car_offline_from_start {
  border-radius: var(--border_rad_mid);
  padding: var(--pad_mid);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 100px;
  background: var(--dark2);
  position: absolute;
  left: calc(50% - 150px);
  top: calc(50% - 50px);
  font-size: 18px;
}

.show_old_data_btn {
  margin-left: var(--marg_mid);
}

.car_offline,
.car_online {
  border-radius: var(--border_rad_min);
  padding: var(--pad_min);
  color: #fff;
  display: inline-block;
}

.car_offline {
  background: var(--deep_red);
}

.car_online {
  background: var(--dark_green);
}

/* Loader */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* Chart */
.chart_selectors {
  margin: var(--marg_far) 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
}

.chart_selector_box {
  margin-right: var(--marg_far);
  margin-bottom: var(--marg_mid);
  /* flex-grow: 1; */
}

.chart_selector {
  padding: var(--pad_min);
  border: 0;
  border-radius: var(--border_rad_min);
  color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-weight: 300;
  background-color: inherit;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.5s;
  height: 44px;
}

.dataset_category {
  font-weight: 700;
  margin-bottom: var(--marg_min);
  display: block;
}

/* Chart selector modal */
#chart_modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--dark);
  z-index: 3;
  padding: var(--marg_mid) var(--marg_min);
}

.disable_scrolling {
  overflow: hidden;
}

#chart_modal {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  height: 100%;
  border-radius: var(--border_rad_min);
  background: var(--dark2);
}

#chart_modal_content {
  overflow-y: scroll;
  height: 100%;
  position: relative;
}

#chart_modal_header {
  display: flex;
  justify-content: space-between;
  background-color: rgba(47, 47, 47, 0.95);
  box-shadow: 0 0 6px 2px var(--dark);
  position: sticky;
  top: 0;
  width: 100%;
  padding: var(--pad_min) var(--pad_mid);
  z-index: 4;
  border-top-left-radius: var(--border_rad_min);
}

.scrollbar_test {
  position: absolute;
  top: -10000px;
  height: 10px;
  width: 10px;
  overflow-y: scroll;
}

.modal_header_round_right_corner {
  border-top-right-radius: var(--border_rad_min);
}

#chart_options {
  padding: var(--pad_min) var(--pad_mid);
  position: relative;
}

/* Chart dataset option */
.chart_option_category {
  margin-top: var(--marg_far);
}

.chart_option_category_title {
  font-weight: 700;
  margin-bottom: var(--marg_min);
  display: block;
}

.dataset_option {
  border: 2px solid var(--blue);
  background: transparent;
  margin-right: var(--marg_min);
  margin-bottom: var(--marg_min);
}

.selected_dataset {
  background-color: var(--blue);
}
