.lap-timer-ui {
    display: flex;
    flex-direction: column;
    width: 95%;
    position: absolute;
    left: 50px;
    /*box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.controls {
    margin-bottom: 20px;
}

.lap-and-chart-container {
    display: flex;
    justify-content: space-between;
    max-width: 95%;
    left: 0px;
}

.lap-times, .chart-container {
    flex: 1; /* Ensure both take up equal space */
    margin-right: 100px; /* Space between the table and chart */
}

.lap-times {
    margin-right: 20px; /* Remove margin-right for the last item */
}

.chart-container {
    margin-right: 0; /* Remove margin-right for the last item */
}

.lap-table {
    width: 100%; /* Ensure the table uses full width */
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow for a smoother look */
}

.lap-table th, .lap-table td {
    border: 1px solid #ddd; /* Keep vertical lines */
    padding: 8px;
    text-align: center;
}

.lap-table th {
    background-color: #2f2f2f;
    border-top: 1px solid #ddd; /* Add top border for header cells */
}

.lap-table td {
    border-bottom: 1px solid #ddd; /* Add bottom border for regular cells */
}

/* Adding rounded corners to the first and last cells in the table */
.lap-table th:first-child {
    border-top-left-radius: 10px;
}

.lap-table th:last-child {
    border-top-right-radius: 10px;
}



.current-lap-time {
    margin-bottom: 10px;
}

.input-field {
    margin-right: 10px;
    width: 80px;
}

.button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    color: white;
    cursor: pointer;
}

.start-button {
    background-color: #4CAF50; /* Green */
}

.lap-button {
    background-color: #008CBA; /* Blue */
}
